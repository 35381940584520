import PropTypes from "prop-types"
import React from "react"

import Helmet from "react-helmet"

const Cookies = ({ siteTitle }) => (
  <>
    <div id="cookieNotification" className="notification">
      <div className="notification__content">
        <h2 className="notification__title">
          We use cookies to help improve user experience.
        </h2>
        <p className="notification__message">
          This includes a range of things such as user personalisation, page
          analytics and more.{" "}
          <a
            target="_blank"
            title="Privacy Policy"
            href="https://www.woodlandtrust.org.uk/privacy-policy/"
            className="notification__button -primary"
          >
            Click here to learn more
          </a>
        </p>
        <div className="notification__footer">
          <div
            id="js-cookie-button"
            className="notification__button -secondary nav-action-sm"
          >
            Close
          </div>
        </div>
      </div>
    </div>
    <script
      id="cookiesScript"
      dangerouslySetInnerHTML={{
        __html: `
        "use strict";function _typeof(e){return(_typeof="function"==typeof Symbol&&"symbol"==typeof Symbol.iterator?function(e){return typeof e}:function(e){return e&&"function"==typeof Symbol&&e.constructor===Symbol&&e!==Symbol.prototype?"symbol":typeof e})(e)}!function(e){var t=!1;if("function"==typeof define&&define.amd&&(define(e),t=!0),"object"==("undefined"==typeof exports?"undefined":_typeof(exports))&&(module.exports=e(),t=!0),!t){var o=window.Cookies,n=window.Cookies=e();n.noConflict=function(){return window.Cookies=o,n}}}(function(){function e(){for(var e=0,t={};e<arguments.length;e++){var o=arguments[e];for(var n in o)t[n]=o[n]}return t}return function t(o){function n(t,i,c){var r;if("undefined"!=typeof document){if(arguments.length>1){if("number"==typeof(c=e({path:"/"},n.defaults,c)).expires){var s=new Date;s.setMilliseconds(s.getMilliseconds()+864e5*c.expires),c.expires=s}c.expires=c.expires?c.expires.toUTCString():"";try{/^[\{\[]/.test(r=JSON.stringify(i))&&(i=r)}catch(e){}i=o.write?o.write(i,t):encodeURIComponent(String(i)).replace(/%(23|24|26|2B|3A|3C|3E|3D|2F|3F|40|5B|5D|5E|60|7B|7D|7C)/g,decodeURIComponent),t=(t=(t=encodeURIComponent(String(t))).replace(/%(23|24|26|2B|5E|60|7C)/g,decodeURIComponent)).replace(/[\(\)]/g,escape);var a="";for(var f in c)c[f]&&(a+="; "+f,!0!==c[f]&&(a+="="+c[f]));return document.cookie=t+"="+i+a}t||(r={});for(var p=document.cookie?document.cookie.split("; "):[],u=/(%[0-9A-Z]{2})+/g,d=0;d<p.length;d++){var l=p[d].split("="),y=l.slice(1).join("=");this.json||'"'!==y.charAt(0)||(y=y.slice(1,-1));try{var m=l[0].replace(u,decodeURIComponent);if(y=o.read?o.read(y,m):o(y,m)||y.replace(u,decodeURIComponent),this.json)try{y=JSON.parse(y)}catch(e){}if(t===m){r=y;break}t||(r[m]=y)}catch(e){}}return r}}return n.set=n,n.get=function(e){return n.call(n,e)},n.getJSON=function(){return n.apply({json:!0},[].slice.call(arguments))},n.defaults={},n.remove=function(t,o){n(t,"",e(o,{expires:-1}))},n.withConverter=t,n}(function(){})});
        var activeClass="-active",
        notification=document.getElementById("cookieNotification"),
        cookieButton=document.getElementById("js-cookie-button"),
        cookieButtonAccept=document.getElementById("js-cookie-button-accept");
        
        if (notification && cookieButton && cookieButtonAccept) {
          Cookies.get("accropress-client-gdpr-accept")||(notification.classList.add(activeClass),
          cookieButton.addEventListener("click",function(){notification.classList.remove(activeClass)}),
          cookieButtonAccept.addEventListener("click",function(){Cookies.set("accropress-client-gdpr-accept",!0,{expires:7}),
          notification.classList.remove(activeClass)}));
        }
            `,
      }}
    />
  </>
)

Cookies.propTypes = {
  siteTitle: PropTypes.string,
}

Cookies.defaultProps = {
  siteTitle: ``,
}

export default Cookies
