import React, { useState, useEffect } from "react"

import { Link } from "gatsby"

const Header = props => {
  const [showMobileNav, setShowMobileNav] = useState(false)
  const [toggleMobileNav, setToggleMobileNav] = useState(false)

  const [scrolled, setScrolled] = useState(false)

  useEffect(() => {
    const handleScroll = event => {
      if (window && window.scrollY === 0) {
        setScrolled(false)
      } else if (window && window.scrollY > 0) {
        setScrolled(true)
      }
    }

    if (window) {
      window.addEventListener("scroll", handleScroll)
    }
  })

  return (
    <header
      id="header"
      className={"header " + (scrolled === true ? "scrolled" : "")}
    >
      <div id="scriptsWrap"></div>
      <div className="navbar top-links__wrapper d-none d-md-none d-lg-flex">
        <div className="container justify-content-between top-links__wrapper__container d-md-none d-lg-flex">
          <a className="navbar-brand d-none d-md-flex" href="/">
            <img
              loading="lazy"
              src="https://cdn2.accropress.com/theme-assets/StPhilips/logo-tag-min.jpg"
              height="72"
              alt="St Philips Care Recruitment"
            />
          </a>
          <ul className="d-none d-md-inline-flex mr-auto header__top-links header__top-links--job-vacancies">
            <li className="top-text">
              <Link to="/vacancies" activeClassName="active">
                Job Vacancies
              </Link>
            </li>
            <li className="top-text">
              <Link to="/about-us" activeClassName="active">
                About Us
              </Link>
            </li>
            <li className="top-text">
              <Link to="/locations" activeClassName="active">
                Care Homes
              </Link>
            </li>
            <li className="top-text">
              <Link to="/contact-us" activeClassName="active">
                Contact Us
              </Link>
            </li>
          </ul>

          <Link
            to="/job-alerts/"
            className="nav-action-sm d-none d-md-flex"
            role="button"
          >
            Get job alerts
          </Link>
        </div>
      </div>
      <div id="wtoverlay-sm" className="page-overlay d-lg-none"></div>
      <div
        className="navbar navbar-tablet-mobile top-links__wrapper d-lg-none d-md-flex p-0"
        style={{ minHeight: "64px" }}
      >
        <a href="/" className="mobile-nav-logo">
          <img
            loading="lazy"
            src="https://cdn2.accropress.com/theme-assets/StPhilips/logo-tag-min.jpg"
            className="h-100"
            alt="St Philips Care Recruitment"
          />
        </a>

        <div className="d-lg-none menuToggler">
          <div className="website-nav-inner-wrap">
            <div
              className="menuToggler"
              onClick={() => setToggleMobileNav(!toggleMobileNav)}
            >
              {!toggleMobileNav ? (
                <div className="fas fa-bars"></div>
              ) : (
                <div className="fas fa-times"></div>
              )}
            </div>
            <ul
              className={
                "bottomNav " + (toggleMobileNav ? "toggledMobile" : "")
              }
            >
              <li className="top-text">
                <Link to="/" activeClassName="active">
                  Home
                </Link>
              </li>

              <li className="top-text">
                <Link to="/vacancies" activeClassName="active">
                  Job Vacancies
                </Link>
              </li>
              <li className="top-text">
                <Link to="/locations" activeClassName="active">
                  Care Homes
                </Link>
              </li>
              <li className="top-text">
                <Link to="/about-us" activeClassName="active">
                  About Us
                </Link>
              </li>
              <li className="top-text">
                <Link to="/contact-us" activeClassName="active">
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <Link
          to="/job-alerts/"
          className="job-alerts-button nav-action-sm nav-action-sm-mobile"
          role="button"
        >
          Get job alerts
        </Link>
      </div>
    </header>
  )
}

export default Header
