import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

class Year extends React.Component {
  render() {
    return <span>{(new Date().getFullYear())}</span>;
  }
}

const Footer = () => (
  <footer className="footer-container">
    <div className="footer">
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
            <h4>Links</h4>
            <ul id="menu-footer">
              <li>
                <a href="/about-us">
                  About Us
                </a>
              </li>
              <li>
                <a href="/vacancies">
                  Job Vacancies
                </a>
              </li>
              <li>
                <a href="/contact-us">
                  Contact Us
                </a>
              </li>
            </ul>
          </div>

          <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
            <h4>Legal</h4>
            <ul id="menu-footer-menu-2">
              <li>
                <a href="/privacy-policy">
                  Privacy Policy
                </a>
              </li>
              <li>
                <a href="/cookie-policy">
                  Cookie Policy
                </a>
              </li>
              <li>
                <a href="/terms-of-service">
                  Terms of Service
                </a>
              </li>
            </ul>
          </div>

          <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
            <h4>St Philips Care</h4>
            <p>
              57 High Street,
              <br />
              Wednesfield,
              <br />
              Wolverhampton
              <br />
              WV11 1ST
            </p>
          </div>
        </div>
      </div>
    </div>

    <div className="bottom-footer">
      <div className="container">
        <div className="sub-footer">
          <ul className="footer__social-links">
            <li className="footer__social-links__item">
              <a href="https://www.facebook.com/stphilscare/" rel="external">               
                <span className="fab fa-facebook"></span>
              </a>
            </li>
          </ul>
        
          <div className="sub-footer__text">
            <p>
              © Copyright <Year /> St Philips Care Group. All rights reserved.               
            </p>
          </div>         
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
